import React, { useState, useEffect } from 'react';
import { 
  Box, 
  TextField, 
  Typography, 
  Paper, 
  IconButton, 
  Button, 
  CircularProgress,
  Stack,
  Container,
  Snackbar,
  Alert
} from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import SaveIcon from '@mui/icons-material/Save';
import { useSelectedChatbot } from '../context/SelectedChatbotContext';
import { fetchTextData, sendChatMessage, saveInstructions } from '../services/api';

// Componente para manejar errores silenciosamente
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: false }; // No mostrar UI de error
  }

  componentDidCatch(error, errorInfo) {
    // Solo registrar en consola si no es un error de ResizeObserver
    if (!error.message?.includes('ResizeObserver')) {
      console.error('Error:', error);
      console.error('Error Info:', errorInfo);
    }
  }

  render() {
    return this.props.children;
  }
}

function Training() {
  // Suprimir completamente los errores de ResizeObserver
  // useEffect(() => {
  //   // Prevenir que el error aparezca en la UI
  //   const errorHandler = (e) => {
  //     if (e.message.includes('ResizeObserver')) {
  //       e.stopPropagation();
  //       e.preventDefault();
  //     }
  //   };

  //   // Suprimir el error en la consola
  //   const originalError = console.error;
  //   console.error = (...args) => {
  //     if (typeof args[0] === 'string' && args[0].includes('ResizeObserver')) {
  //       return;
  //     }
  //     originalError.apply(console, args);
  //   };

  //   // Capturar el error antes de que llegue a la UI
  //   window.addEventListener('error', errorHandler);
  //   window.addEventListener('unhandledrejection', errorHandler);

  //   return () => {
  //     console.error = originalError;
  //     window.removeEventListener('error', errorHandler);
  //     window.removeEventListener('unhandledrejection', errorHandler);
  //   };
  // }, []);

  const [instructions, setInstructions] = useState('');
  const [currentMessage, setCurrentMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [originalInstructions, setOriginalInstructions] = useState('');
  const [isModified, setIsModified] = useState(false);
  const [chatId, setChatId] = useState(null);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const { selectedChatbot } = useSelectedChatbot();

  useEffect(() => {
    const loadInstructions = async () => {
      if (!selectedChatbot) {
        setInstructions('');
        setOriginalInstructions('');
        setError(null);
        setIsModified(false);
        return;
      }
      
      try {
        setError(null);
        const data = await fetchTextData(selectedChatbot);
        
        if (!data || !data.text) {
          setError('No se encontraron instrucciones para este chatbot');
          return;
        }
        
        setInstructions(data.text);
        setOriginalInstructions(data.text);
        setIsModified(false);
      } catch (err) {
        console.error('Error loading instructions:', err);
        setError('Error al cargar las instrucciones del chatbot');
        setInstructions('');
        setOriginalInstructions('');
      }
    };

    loadInstructions();
  }, [selectedChatbot]);

  const handleInstructionsChange = (event) => {
    const newText = event.target.value;
    setInstructions(newText);
    setIsModified(newText !== originalInstructions);
  };

  const handleSaveChanges = async () => {
    if (!selectedChatbot || !instructions.trim()) return;
    
    setIsSaving(true);
    setSaveError(null);
    setSaveSuccess(false);

    try {
      await saveInstructions(selectedChatbot, instructions);
      setOriginalInstructions(instructions);
      setIsModified(false);
      setSaveSuccess(true);
      // Reiniciar chatId para que la próxima conversación use las instrucciones actualizadas
      setChatId('');
      // Limpiar mensajes anteriores ya que el contexto ha cambiado
      setMessages([]);
    } catch (error) {
      setSaveError(error.message || 'Error al guardar los cambios');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSendMessage = async () => {
    if (!currentMessage.trim() || !selectedChatbot) return;

    const messageText = currentMessage.trim();
    setCurrentMessage('');

    // Añadir mensaje del usuario
    const userMessage = { role: 'user', content: messageText };
    setMessages(prev => [...prev, userMessage]);

    // Añadir mensaje de "escribiendo..."
    const typingMessage = { role: 'assistant', content: 'Escribiendo...', isTyping: true };
    setMessages(prev => [...prev, typingMessage]);

    setIsSendingMessage(true);

    try {
      const response = await sendChatMessage(selectedChatbot, messageText, chatId);

      // Remover el mensaje de "escribiendo..."
      setMessages(prev => prev.filter(msg => !msg.isTyping));

      if (response && response.message) {
        const botMessage = { role: 'assistant', content: response.message };
        setMessages(prev => [...prev, botMessage]);
        
        if (response.chatId) {
          setChatId(response.chatId);
        }
      } else {
        throw new Error('El servidor no envió una respuesta válida');
      }
    } catch (error) {
      // Remover el mensaje de "escribiendo..."
      setMessages(prev => prev.filter(msg => !msg.isTyping));

      console.error('Error al enviar mensaje:', error);
      
      // Mostrar mensaje de error más amigable
      const errorMessage = { 
        role: 'assistant', 
        content: error.message || 'Lo siento, ha ocurrido un error al procesar tu mensaje. Por favor, inténtalo de nuevo más tarde.',
        isError: true
      };
      
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsSendingMessage(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <ErrorBoundary>
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Stack spacing={3}>
          {/* Header */}
          <Paper
            elevation={3}
            sx={{
              backgroundColor: '#0b151b',
              border: '1px solid #205a7b',
              borderRadius: 2,
              p: 3
            }}
          >
            <Typography variant="h4" sx={{ color: '#ffffff', mb: 1 }}>
              Entrenamiento
            </Typography>
            <Typography variant="body1" sx={{ color: '#96c3dd' }}>
              Configura las instrucciones del asistente y prueba su comportamiento en tiempo real
            </Typography>
          </Paper>

          {/* Instructions */}
          <Paper
            elevation={3}
            sx={{
              backgroundColor: '#0b151b',
              border: '1px solid #205a7b',
              borderRadius: 2,
            }}
          >
            <Stack>
              <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid #205a7b',
                backgroundColor: 'rgba(150, 195, 221, 0.05)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <Typography variant="h6" sx={{ color: '#ecf2f6', fontWeight: 500 }}>
                  Instrucciones del Asistente
                </Typography>
                {isModified && (
                  <Button
                    variant="contained"
                    onClick={handleSaveChanges}
                    startIcon={isSaving ? <CircularProgress size={20} sx={{ color: '#fff' }} /> : <SaveIcon />}
                    disabled={isSaving}
                    sx={{
                      backgroundColor: '#205a7b',
                      color: '#ecf2f6',
                      px: 3,
                      py: 1,
                      fontSize: '0.9rem',
                      fontWeight: 500,
                      textTransform: 'none',
                      '&:hover': {
                        backgroundColor: '#4aa6da',
                      },
                      '&.Mui-disabled': {
                        backgroundColor: '#0d1b24',
                        color: '#205a7b',
                      },
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    {isSaving ? 'Guardando...' : 'Guardar Cambios'}
                  </Button>
                )}
              </Box>

              {selectedChatbot ? (
                <Box sx={{ p: 2, height: '300px', overflow: 'auto' }}>
                  <TextField
                    multiline
                    fullWidth
                    value={instructions}
                    onChange={handleInstructionsChange}
                    variant="outlined"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        color: '#ecf2f6',
                        fontFamily: 'monospace',
                        fontSize: '0.9rem',
                        '& fieldset': {
                          borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                          borderColor: 'transparent',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'transparent',
                        },
                      },
                    }}
                  />
                </Box>
              ) : (
                <Typography sx={{ p: 2, color: '#96c3dd' }}>
                  Selecciona un asistente para ver sus instrucciones
                </Typography>
              )}
            </Stack>
          </Paper>

          {/* Chat */}
          <Paper
            elevation={3}
            sx={{
              backgroundColor: '#0b151b',
              border: '1px solid #205a7b',
              borderRadius: 2,
            }}
          >
            <Stack sx={{ height: '400px' }}>
              <Box sx={{ 
                p: 2, 
                borderBottom: '1px solid #205a7b',
                backgroundColor: 'rgba(150, 195, 221, 0.05)'
              }}>
                <Typography variant="h6" sx={{ color: '#ecf2f6', fontWeight: 500 }}>
                  Probar Asistente
                </Typography>
              </Box>

              <Box sx={{ flex: 1, overflow: 'auto', p: 2 }}>
                <Stack spacing={1}>
                  {messages.map((msg, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start',
                      }}
                    >
                      <Paper
                        sx={{
                          maxWidth: '80%',
                          p: 1.5,
                          backgroundColor: msg.role === 'user' ? '#205a7b' : '#162b36',
                          color: '#ecf2f6',
                        }}
                      >
                        <Typography variant="body2">{msg.content}</Typography>
                      </Paper>
                    </Box>
                  ))}
                </Stack>
              </Box>

              <Box sx={{ 
                p: 2, 
                borderTop: '1px solid #205a7b',
                backgroundColor: 'rgba(150, 195, 221, 0.05)',
                display: 'flex',
                gap: 1
              }}>
                <TextField
                  fullWidth
                  value={currentMessage}
                  onChange={(e) => setCurrentMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Escribe un mensaje..."
                  variant="outlined"
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: 'rgba(150, 195, 221, 0.05)',
                      color: '#ecf2f6',
                      '& fieldset': {
                        borderColor: '#205a7b',
                      },
                      '&:hover fieldset': {
                        borderColor: '#4aa6da',
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: '#4aa6da',
                      },
                    },
                  }}
                />
                <IconButton 
                  onClick={handleSendMessage}
                  disabled={!currentMessage.trim() || isSendingMessage}
                  sx={{ 
                    backgroundColor: '#205a7b',
                    color: '#ecf2f6',
                    '&:hover': {
                      backgroundColor: '#4aa6da',
                    },
                    '&.Mui-disabled': {
                      backgroundColor: '#0d1b24',
                      color: '#205a7b',
                    }
                  }}
                >
                  {isSendingMessage ? (
                    <CircularProgress size={24} sx={{ color: '#ecf2f6' }} />
                  ) : (
                    <SendIcon />
                  )}
                </IconButton>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Container>

      {/* Notificaciones */}
      <Snackbar 
        open={saveSuccess} 
        autoHideDuration={3000} 
        onClose={() => setSaveSuccess(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success" sx={{ backgroundColor: '#205a7b', color: '#ecf2f6' }}>
          Cambios guardados correctamente
        </Alert>
      </Snackbar>

      <Snackbar 
        open={!!saveError} 
        autoHideDuration={5000} 
        onClose={() => setSaveError(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="error" sx={{ backgroundColor: '#8b1e1e', color: '#ecf2f6' }}>
          {saveError}
        </Alert>
      </Snackbar>
    </ErrorBoundary>
  );
};

export default Training;
