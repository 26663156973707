import React, { useState } from 'react';
import {
  Container,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';
import { useDateRange } from '../context/DateContext';
import dayjs from 'dayjs';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setStartDate, setEndDate } = useDateRange();

  // Verificar si los campos están rellenos
  const isFormFilled = username && password;

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const users = [
        { 
          username: 'juanpe', 
          password: '1234', 
          name: 'Juan Pedro Cabanas',
          role: 'admin', 
          chatbots: [
            { id: 'beemood', name: 'Beemood' },
            { id: 'ai4logic', name: 'AI4Logic' },
            { id: 'quarzoapps', name: 'QuarzoApps' },
            { id: 'beersstorming', name: 'BeersStorming' },
            { id: 'tpnet', name: 'TPNet' },
            { id: 'sedaser', name: 'Sedaser' },
            { id: 'headteam', name: 'HeadTeam' },
            { id: 'datatrends', name: 'Datatrends' }
          ]
        },
        {
          username: 'josed',
          password: '1234',
          name: 'JD',
          role: 'admin',
          chatbots: [
            { id: 'beemood', name: 'Beemood' },
            { id: 'ai4logic', name: 'AI4Logic' },
            { id: 'quarzoapps', name: 'QuarzoApps' },
            { id: 'beersstorming', name: 'BeersStorming' },
            { id: 'tpnet', name: 'TPNet' },
            { id: 'sedaser', name: 'Sedaser' },
            { id: 'headteam', name: 'HeadTeam' },
            { id: 'datatrends', name: 'Datatrends' }
          ]
        },
        {
          username: 'admin1',
          password: '1234',
          name: 'Ai4logic admin',
          role: 'admin',
          chatbots: [{ id: 'ai4logic', name: 'AI4Logic' }]
        },
        { 
          username: 'admin2', 
          password: '1234', 
          name: 'Beemood',
          role: 'admin', 
          chatbots: [{ id: 'beemood', name: 'Beemood' }]
        },
        { 
          username: 'admin3', 
          password: '1234', 
          name: 'Quarzoapps',
          role: 'admin', 
          chatbots: [{ id: 'quarzoapps', name: 'QuarzoApps' }]
        },
        {
          username: 'beersstorming',
          password: '1234',
          name: 'BeersStorming',
          role: 'admin',
          chatbots: [{ id: 'beersstorming', name: 'BeersStorming' }]
        },
        {
          username: 'tpnet',
          password: '1234',
          name: 'TPNet',
          role: 'admin',
          chatbots: [{ id: 'tpnet', name: 'TPNet' }]
        },
        {
          username: 'sedaser',
          password: '1234',
          name: 'Sedaser',
          role: 'admin',
          chatbots: [{ id: 'sedaser', name: 'Sedaser' }]
        },
        {
          username: 'headteam',
          password: '1234',
          name: 'HeadTeam',
          role: 'admin',
          chatbots: [{ id: 'headteam', name: 'HeadTeam' }]
        },
        {
          username: 'datatrends',
          password: '1234',
          name: 'Datatrends',
          role: 'admin',
          chatbots: [{ id: 'datatrends', name: 'Datatrends' }]
        },
        {
          username: 'demo1',
          password: '1234',
          name: 'Demo 1',
          role: 'admin',
          chatbots: [{ id: '_demo1', name: 'Demo 1' }]
        }
      ];

      const user = users.find(u => u.username === username && u.password === password);

      if (user) {
        // Guardar el usuario con el primer chatbot como seleccionado
        const userData = {
          ...user,
          chatbot: user.chatbots[0].id
        };
        sessionStorage.setItem('user', JSON.stringify(userData));
        
        // Establecer fechas por defecto a los últimos 30 días
        setStartDate(dayjs().subtract(30, 'days'));
        setEndDate(dayjs());
        navigate('/');
      } else {
        setError('Usuario o contraseña incorrectos');
      }
    } catch (error) {
      setError('Error al iniciar sesión');
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#0b151b',
        backgroundImage: 'linear-gradient(135deg, #0b151b 0%, #1a2c3d 100%)',
      }}
    >
      <Container component="main" maxWidth="xs">
        <Paper
          elevation={24}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor: 'rgba(11, 21, 27, 0.8)',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(150, 195, 221, 0.12)',
            borderRadius: 3,
          }}
        >
          <Box
            sx={{
              width: 56,
              height: 56,
              borderRadius: '50%',
              backgroundColor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <LockOutlinedIcon sx={{ fontSize: 32, color: '#0b151b' }} />
          </Box>

          <Typography
            component="h1"
            variant="h4"
            sx={{
              mb: 3,
              background: 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              fontWeight: 700,
            }}
          >
            Iniciar Sesión
          </Typography>

          <Box component="form" onSubmit={handleLogin} sx={{ mt: 1, width: '100%' }}>
            {error && (
              <Alert 
                severity="error" 
                sx={{ 
                  mb: 2,
                  backgroundColor: 'rgba(211, 47, 47, 0.1)',
                  color: '#ff5252',
                }}
              >
                {error}
              </Alert>
            )}

            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Usuario"
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.3)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#96c3dd',
                  },
                },
                input: { color: '#96c3dd' },
                label: { color: '#96c3dd' }
              }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.2)',
                  },
                  '&:hover fieldset': {
                    borderColor: 'rgba(150, 195, 221, 0.3)',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#96c3dd',
                  },
                },
                input: { color: '#96c3dd' },
                label: { color: '#96c3dd' }
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                py: 1.5,
                fontSize: '1.1rem',
                fontWeight: 600,
                textTransform: 'none',
                background: isFormFilled 
                  ? 'linear-gradient(45deg, #96c3dd 30%, #4aa6da 90%)'
                  : 'linear-gradient(45deg, rgba(150, 195, 221, 0.3) 30%, rgba(74, 166, 218, 0.3) 90%)',
                boxShadow: isFormFilled 
                  ? '0 3px 5px 2px rgba(74, 166, 218, .3)'
                  : 'none',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: isFormFilled 
                    ? 'linear-gradient(45deg, #4aa6da 30%, #96c3dd 90%)'
                    : 'linear-gradient(45deg, rgba(150, 195, 221, 0.4) 30%, rgba(74, 166, 218, 0.4) 90%)',
                  boxShadow: isFormFilled 
                    ? '0 4px 6px 2px rgba(74, 166, 218, .4)'
                    : '0 2px 4px rgba(74, 166, 218, .2)',
                },
                transform: isFormFilled ? 'scale(1.02)' : 'none',
              }}
            >
              Iniciar Sesión
            </Button>
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Login;
