import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import MainLayout from './components/Layout/MainLayout';
import ProtectedRoute from './components/ProtectedRoute';
import Home from './pages/home';
import Conversations from './pages/conversations';
import Analytics from './pages/analytics';
import Login from './pages/login';
import Profile from './pages/profile';
import Training from './pages/Training';
import Settings from './pages/Settings';
import { DateProvider } from './context/DateContext';
import { SelectedChatbotProvider } from './context/SelectedChatbotContext';
import './App.css';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#96c3dd',
    },
    secondary: {
      main: '#205a7b',
    },
    background: {
      default: '#0b151b',
      paper: '#0b151b',
    },
    text: {
      primary: '#ecf2f6',
      secondary: '#96c3dd',
    },
    action: {
      active: '#4aa6da',
      hover: '#205a7b',
    },
  },
  typography: {
    allVariants: {
      color: '#ecf2f6',
    },
    h4: {
      fontWeight: 600,
    },
    h6: {
      fontWeight: 600,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#0b151b',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#0b151b',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#0b151b',
          borderRight: '1px solid #205a7b',
        },
      },
    },
  },
});

const RedirectComponent = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (window.location.hash === '') {
      const user = JSON.parse(sessionStorage.getItem('user'));
      navigate(user ? '/' : '/login');
    }
  }, [navigate]);
  return null;
};

function App() {
  return (
    <DateProvider>
      <SelectedChatbotProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <RedirectComponent />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/*"
                element={
                  <ProtectedRoute>
                    <MainLayout>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/conversations" element={<Conversations />} />
                        <Route path="/analytics" element={<Analytics />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/training" element={<Training />} />
                        <Route path="/settings" element={<Settings />} />
                      </Routes>
                    </MainLayout>
                  </ProtectedRoute>
                }
              />
            </Routes>
          </Router>
        </ThemeProvider>
      </SelectedChatbotProvider>
    </DateProvider>
  );
}

export default App;
