import React, { useState } from 'react';
import { Box, Button, Typography, Stack, IconButton, useTheme, useMediaQuery } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import dayjs from 'dayjs';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';

const predefinedRanges = [
  { 
    label: 'Personalizado',
    getValue: () => null
  },
  {
    label: 'Hoy',
    getValue: () => ({
      start: dayjs().startOf('day'),
      end: dayjs().endOf('day')
    })
  },
  {
    label: 'Ayer',
    getValue: () => ({
      start: dayjs().subtract(1, 'day').startOf('day'),
      end: dayjs().subtract(1, 'day').endOf('day')
    })
  },
  {
    label: 'Esta semana',
    getValue: () => ({
      start: dayjs().startOf('week'),
      end: dayjs().endOf('day')
    })
  },
  {
    label: 'Semana pasada',
    getValue: () => ({
      start: dayjs().subtract(1, 'week').startOf('week'),
      end: dayjs().subtract(1, 'week').endOf('week')
    })
  },
  {
    label: 'Últimos 7 días',
    getValue: () => ({
      start: dayjs().subtract(7, 'day').startOf('day'),
      end: dayjs().endOf('day')
    })
  },
  {
    label: 'Últimos 30 días',
    getValue: () => ({
      start: dayjs().subtract(30, 'day').startOf('day'),
      end: dayjs().endOf('day')
    })
  },
  {
    label: 'Últimos 90 días',
    getValue: () => ({
      start: dayjs().subtract(90, 'day').startOf('day'),
      end: dayjs().endOf('day')
    })
  },
  {
    label: 'Últimos 12 meses',
    getValue: () => ({
      start: dayjs().subtract(12, 'month').startOf('day'),
      end: dayjs().endOf('day')
    })
  }
];

const DateRangePicker = ({ startDate, endDate, onStartDateChange, onEndDateChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState('Personalizado');
  const [tempStartDate, setTempStartDate] = useState(startDate);
  const [tempEndDate, setTempEndDate] = useState(endDate);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const handleApply = () => {
    onStartDateChange(tempStartDate);
    onEndDateChange(tempEndDate);
    handleClose();
  };

  const handleRangeSelect = (range) => {
    setSelectedRange(range.label);
    const dates = range.getValue();
    if (dates) {
      setTempStartDate(dates.start);
      setTempEndDate(dates.end);
      if (range.label !== 'Personalizado') {
        onStartDateChange(dates.start);
        onEndDateChange(dates.end);
        handleClose();
      }
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Button
        onClick={handleOpen}
        startIcon={<CalendarTodayIcon />}
        sx={{
          color: 'text.secondary',
          backgroundColor: 'background.paper',
          border: '1px solid',
          borderColor: 'rgba(150, 195, 221, 0.12)',
          '&:hover': {
            backgroundColor: 'rgba(150, 195, 221, 0.08)',
          },
          px: 2,
          py: 1,
        }}
      >
        <Typography variant="body2" noWrap>
          {startDate.format('DD/MM/YYYY')} - {endDate.format('DD/MM/YYYY')}
        </Typography>
      </Button>

      <Dialog
        open={isOpen}
        onClose={handleClose}
        maxWidth={false}
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            backgroundImage: 'none',
            width: isMobile ? '100%' : '900px',
            height: isMobile ? '100%' : 'auto',
          }
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            height: '100%',
            maxHeight: { xs: '100vh', sm: 'auto' },
          }}>
            {/* Panel izquierdo con rangos predefinidos */}
            <Stack
              spacing={1}
              sx={{
                p: 2,
                borderRight: { xs: 'none', sm: '1px solid rgba(150, 195, 221, 0.12)' },
                borderBottom: { xs: '1px solid rgba(150, 195, 221, 0.12)', sm: 'none' },
                minWidth: { xs: '100%', sm: '200px' },
                maxWidth: { sm: '200px' },
              }}
            >
              {predefinedRanges.map((range) => (
                <Button
                  key={range.label}
                  onClick={() => handleRangeSelect(range)}
                  variant={selectedRange === range.label ? 'contained' : 'text'}
                  sx={{
                    justifyContent: 'flex-start',
                    px: 2,
                    py: 1,
                    color: selectedRange === range.label ? 'white' : 'text.secondary',
                  }}
                >
                  {range.label}
                </Button>
              ))}
            </Stack>

            {/* Panel derecho con calendarios */}
            <Box sx={{ 
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              flexGrow: 1,
              gap: 2,
              p: 2,
              overflow: { xs: 'auto', sm: 'visible' }
            }}>
              <Box sx={{ 
                flex: 1,
                '& .MuiPickersLayout-root': {
                  minHeight: { sm: 'auto' }
                }
              }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={tempStartDate}
                  onChange={(newValue) => setTempStartDate(newValue)}
                  slotProps={{
                    toolbar: { hidden: true },
                    layout: {
                      sx: {
                        '& .MuiPickersCalendarHeader-root': {
                          paddingLeft: '8px',
                          paddingRight: '8px',
                          marginTop: '8px',
                          marginBottom: '8px',
                        },
                        '& .MuiDayCalendar-header': {
                          paddingTop: '4px',
                          paddingBottom: '4px',
                        },
                        '& .MuiDayCalendar-weekContainer': {
                          margin: '2px 0',
                        },
                      }
                    },
                    day: {
                      sx: {
                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                        width: { xs: 32, sm: 36 },
                        height: { xs: 32, sm: 36 },
                        margin: '1px',
                      }
                    }
                  }}
                />
              </Box>
              <Box sx={{ 
                flex: 1,
                '& .MuiPickersLayout-root': {
                  minHeight: { sm: 'auto' }
                }
              }}>
                <StaticDatePicker
                  displayStaticWrapperAs="desktop"
                  value={tempEndDate}
                  onChange={(newValue) => setTempEndDate(newValue)}
                  slotProps={{
                    toolbar: { hidden: true },
                    layout: {
                      sx: {
                        '& .MuiPickersCalendarHeader-root': {
                          paddingLeft: '8px',
                          paddingRight: '8px',
                          marginTop: '8px',
                          marginBottom: '8px',
                        },
                        '& .MuiDayCalendar-header': {
                          paddingTop: '4px',
                          paddingBottom: '4px',
                        },
                        '& .MuiDayCalendar-weekContainer': {
                          margin: '2px 0',
                        },
                      }
                    },
                    day: {
                      sx: {
                        fontSize: { xs: '0.75rem', sm: '0.875rem' },
                        width: { xs: 32, sm: 36 },
                        height: { xs: 32, sm: 36 },
                        margin: '1px',
                      }
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>

          {/* Botones de acción */}
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'flex-end', 
            gap: 1, 
            p: 2,
            borderTop: '1px solid rgba(150, 195, 221, 0.12)',
          }}>
            <Button onClick={handleClose} variant="text">
              Cancelar
            </Button>
            <Button onClick={handleApply} variant="contained">
              Aplicar
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </LocalizationProvider>
  );
};

export default DateRangePicker;
