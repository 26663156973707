import React, { createContext, useContext, useState } from 'react';
import dayjs from 'dayjs';

const DateContext = createContext();

export function DateProvider({ children }) {
  const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(dayjs());

  return (
    <DateContext.Provider value={{ startDate, setStartDate, endDate, setEndDate }}>
      {children}
    </DateContext.Provider>
  );
}

export function useDateRange() {
  const context = useContext(DateContext);
  if (!context) {
    throw new Error('useDateRange must be used within a DateProvider');
  }
  return context;
}
