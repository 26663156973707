import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

const Settings = () => {
  return (
    <Box>
      {/* Header Section */}
      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Ajustes
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Configuración general y personalización del asistente virtual
        </Typography>
      </Box>

      {/* Content Section */}
      <Paper sx={{ p: 3, backgroundColor: 'rgba(255, 255, 255, 0.05)' }}>
        {/* Aquí irá el contenido de la página de ajustes */}
      </Paper>
    </Box>
  );
};

export default Settings;
