import React, { useState, useEffect, useMemo } from 'react';
import { useDateRange } from '../context/DateContext';
import {
  Box,
  Container,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Grid,
  Tooltip,
  Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DateRangePicker from '../components/DateRangePicker';
import { fetchData, fetchChatConversation } from '../services/api';
import dayjs from 'dayjs';

// Estilos personalizados
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: '#ecf2f6',
  borderBottom: '1px solid rgba(32, 90, 123, 0.5)',
  padding: '16px',
  '&.header': {
    backgroundColor: '#0b151b',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    '&.numeric': {
      width: '55px',
      minWidth: '55px',
      maxWidth: '55px',
      padding: '4px 8px',
      textAlign: 'center',
      '& .MuiTableSortLabel-root': {
        display: 'inline-flex',
        '& .MuiTableSortLabel-icon': {
          marginLeft: '4px',
          position: 'static'
        }
      }
    },
    '&.fecha': {
      paddingRight: '32px',
    }
  },
  '&.numeric': {
    width: '55px',
    minWidth: '55px',
    maxWidth: '55px',
    padding: '4px 8px',
    textAlign: 'center',
  },
  '&.fecha': {
    paddingRight: '32px',
  },
  '&.consulta': {
    padding: '12px 16px',
    '& .consulta-text': {
      color: '#ecf2f6',
      marginBottom: '4px',
      display: 'block',
    },
    '& .respuesta-text': {
      color: '#96c3dd',
      paddingLeft: '16px',
      display: 'block',
      fontSize: '0.95em',
    }
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#0b151b',
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#0f1e27',
  },
  '&:hover': {
    backgroundColor: '#162d3a',
    cursor: 'pointer',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: '#0b151b',
    color: '#96c3dd',
    border: '1px solid #205a7b',
  },
}));

const StyledMetricBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#162d3a',
  padding: '12px',
  borderRadius: '8px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .label': {
    color: '#96c3dd',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    marginBottom: '4px',
    height: '16px',
    textAlign: 'center'
  },
  '& .value': {
    color: '#ecf2f6',
    fontSize: '1.2rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    textAlign: 'center',
    maxWidth: '100%',
    padding: '0 4px'
  },
  '& .personalDataValue': {
    color: '#ecf2f6',
    fontSize: '0.9rem',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    maxWidth: '100%',
    padding: '0 4px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    lineHeight: 1.2,
    overflow: 'hidden',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical'
  },
  '& .date': {
    color: '#ecf2f6',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
    width: '100%'
  },
  '& .time': {
    color: '#ecf2f6',
    fontSize: '0.75rem',
    marginTop: '1px',
    textAlign: 'center',
    width: '100%'
  }
}));

const CategoryLabel = styled(Typography)(({ theme }) => ({
  color: '#96c3dd',
  fontWeight: 'bold',
  fontSize: '0.9rem',
  marginTop: '16px',
  marginBottom: '8px',
  marginLeft: '8px'
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#0f1e27',
  padding: '16px',
  borderRadius: '8px',
  marginTop: '24px',
  border: '1px solid #205a7b',
  minHeight: '100px',
  maxHeight: '500px',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#0b151b',
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#205a7b',
    borderRadius: '4px',
  },
}));

const MessageBubble = styled(Box)(({ isUser }) => ({
  maxWidth: '70%',
  marginBottom: '12px',
  alignSelf: isUser ? 'flex-end' : 'flex-start',
  backgroundColor: isUser ? '#1a5f7a' : '#162d3a',
  padding: '12px 16px',
  borderRadius: '12px',
  borderTopRightRadius: isUser ? '4px' : '12px',
  borderTopLeftRadius: isUser ? '12px' : '4px',
  color: '#ecf2f6',
  boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
  position: 'relative',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    [isUser ? 'right' : 'left']: '-8px',
    borderTop: `8px solid ${isUser ? '#1a5f7a' : '#162d3a'}`,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
  },
}));

const formatChatText = (text) => {
  if (!text) return [];
  
  const messages = [];
  let currentMessage = null;
  
  text.split('\n').forEach(line => {
    if (line.startsWith('Usuario : ')) {
      if (currentMessage) messages.push(currentMessage);
      currentMessage = {
        isUser: true,
        speaker: 'Usuario',
        message: line.substring('Usuario : '.length).trim()
      };
    } else if (line.startsWith('4LogicONE : ') || line.startsWith('Asistente : ')) {
      if (currentMessage) messages.push(currentMessage);
      const speaker = line.startsWith('4LogicONE : ') ? '4LogicONE' : 'Asistente';
      currentMessage = {
        isUser: false,
        speaker: speaker,
        message: line.substring(line.indexOf(' : ') + 3).trim()
      };
    } else if (currentMessage && line.trim()) {
      // Agregar líneas adicionales al mensaje actual
      currentMessage.message += '\n' + line.trim();
    }
  });
  
  // Agregar el último mensaje si existe
  if (currentMessage) messages.push(currentMessage);
  
  return messages;
};

const formatTime = (fecha, hora) => {
  return dayjs(fecha + hora, 'YYYYMMDDHHmmss').format('HH:mm:ss');
};

const formatDate = (fecha) => {
  return dayjs(fecha, 'YYYYMMDD').format('DD/MM/YYYY');
};

const formatDateTimeForBox = (fecha, hora) => {
  return {
    date: dayjs(fecha, 'YYYYMMDD').format('DD/MM/YYYY'),
    time: dayjs(hora, 'HHmmss').format('HH:mm:ss')
  };
};

const hasPersonalData = (chatDetail) => {
  return chatDetail.nombre || chatDetail.telefono || chatDetail.email || 
         chatDetail.direccion || chatDetail.referencia || chatDetail.referencias;
};

const getPersonalDataBoxes = (chatDetail) => {
  const boxes = [];
  if (chatDetail.nombre) boxes.push({ label: 'Nombre', value: chatDetail.nombre });
  if (chatDetail.telefono) boxes.push({ label: 'Teléfono', value: chatDetail.telefono });
  if (chatDetail.email) boxes.push({ label: 'Email', value: chatDetail.email });
  if (chatDetail.direccion) boxes.push({ label: 'Dirección', value: chatDetail.direccion });
  if (chatDetail.referencia) boxes.push({ label: 'Referencia', value: chatDetail.referencia });
  if (chatDetail.referencias) boxes.push({ label: 'Referencias', value: chatDetail.referencias });
  return boxes;
};

const Conversations = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [chatDetail, setChatDetail] = useState(null);
  const { startDate, endDate, setStartDate, setEndDate } = useDateRange();
  const [orderBy, setOrderBy] = useState('fecha');
  const [order, setOrder] = useState('desc');
  const [showOnlyWithPersonalData, setShowOnlyWithPersonalData] = useState(false);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filteredAndSortedChats = useMemo(() => {
    let filtered = [...chats];
    
    if (showOnlyWithPersonalData) {
      filtered = filtered.filter(hasPersonalData);
    }

    return filtered.sort((a, b) => {
      if (orderBy === 'fecha') {
        const dateA = dayjs(a.fecha + ' ' + a.hora, 'YYYYMMDD HHmmss');
        const dateB = dayjs(b.fecha + ' ' + b.hora, 'YYYYMMDD HHmmss');
        return order === 'asc' ? dateA.diff(dateB) : dateB.diff(dateA);
      }
      
      const valueA = a[orderBy];
      const valueB = b[orderBy];
      
      if (typeof valueA === 'string') {
        return order === 'asc'
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
      
      return order === 'asc' ? valueA - valueB : valueB - valueA;
    });
  }, [chats, order, orderBy, showOnlyWithPersonalData]);

  const getColorForValue = (value) => {
    if (value <= 3) return '#ff5252';
    if (value <= 6) return '#ffd740';
    return '#69f0ae';
    };

  const getColorForValueUrg = (value) => {
    if (value <= 3) return '#69f0ae';
    if (value <= 6) return '#ffd740';
    return '#ff5252';
    };

  useEffect(() => {
    const fetchChats = async () => {
      setLoading(true);
      setError(null);
      try {
        const user = JSON.parse(sessionStorage.getItem('user'));
        const selectedChatbot = user.chatbot || user.selectedChatbot;

        if (!selectedChatbot) {
          setError('No hay chatbot seleccionado');
          return;
        }

        const response = await fetchData(
          1,
          selectedChatbot,
          startDate.format('YYYYMMDD'),
          endDate.format('YYYYMMDD')
        );
        setChats(response || []);
        setError(null);
      } catch (err) {
        console.error('Error al cargar las conversaciones:', err);
        setError('Error al cargar las conversaciones. Por favor, inténtelo de nuevo más tarde.');
        setChats([]);
      } finally {
        setLoading(false);
      }
    };

    fetchChats();
  }, [startDate, endDate]);

  useEffect(() => {
    const handleChatbotChange = () => {
      setSelectedChat(null);
      setChatDetail(null);
      setOpenDialog(false);
      setChats([]);
      const fetchChats = async () => {
        setLoading(true);
        setError(null);
        try {
          const user = JSON.parse(sessionStorage.getItem('user'));
          const selectedChatbot = user.chatbot || user.selectedChatbot;

          if (!selectedChatbot) {
            setError('No hay chatbot seleccionado');
            return;
          }

          const response = await fetchData(
            1,
            selectedChatbot,
            startDate.format('YYYYMMDD'),
            endDate.format('YYYYMMDD')
          );
          setChats(response || []);
          setError(null);
        } catch (err) {
          console.error('Error al cargar las conversaciones:', err);
          setError('Error al cargar las conversaciones. Por favor, inténtelo de nuevo más tarde.');
          setChats([]);
        } finally {
          setLoading(false);
        }
      };
      fetchChats();
    };

    window.addEventListener('chatbotChanged', handleChatbotChange);
    return () => {
      window.removeEventListener('chatbotChanged', handleChatbotChange);
    };
  }, [startDate, endDate]);

  const handleChatClick = async (chat) => {
    try {
      setSelectedChat(chat);
      setOpenDialog(true);
      const user = JSON.parse(sessionStorage.getItem('user'));
      const selectedChatbot = user.chatbot || user.selectedChatbot;

      if (!selectedChatbot) {
        setError('No hay chatbot seleccionado');
        return;
      }

      const data = await fetchChatConversation(
        1,
        selectedChatbot,
        startDate.format('YYYYMMDD'),
        endDate.format('YYYYMMDD'),
        chat.chatid
      );
      if (data && data.length > 0) {
        setChatDetail(data[0]);
      }
    } catch (error) {
      console.error('Error al obtener el detalle del chat:', error);
      setError('Error al cargar los detalles de la conversación');
    }
  };

  const formatDateTime = (fecha, hora) => {
    const formattedDate = dayjs(fecha + hora, 'YYYYMMDDHHmmss').format('DD/MM/YYYY HH:mm:ss');
    return formattedDate;
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3, backgroundColor: '#0b151b', minHeight: '100vh' }}>
      <Paper 
        elevation={3}
        sx={{ 
          p: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 3 },
          borderRadius: 2,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: 2,
          backgroundColor: '#0b151b',
          border: '1px solid #205a7b',
        }}
      >
        <Box>
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#ecf2f6' }}>
            Conversaciones
          </Typography>
          <Typography variant="body1" sx={{ color: '#96c3dd' }}>
            Historial de conversaciones del 4LogicOne
          </Typography>
        </Box>
        <Box>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
        </Box>
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Paper 
          sx={{ 
            p: 3, 
            textAlign: 'center',
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
          }}
        >
          <Typography color="error">{error}</Typography>
        </Paper>
      ) : !chats || chats.length === 0 ? (
        <Paper 
          sx={{ 
            p: 3, 
            textAlign: 'center',
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
          }}
        >
          <Typography sx={{ color: '#96c3dd' }}>
            No hay registros de conversaciones para el período seleccionado.
          </Typography>
        </Paper>
      ) : (
        <TableContainer 
          component={Paper} 
          sx={{ 
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
            borderRadius: 2,
            maxWidth: 'calc(100vw - 48px)',
            marginRight: '8px'
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell className="header">
                  Consulta
                </StyledTableCell>
                <StyledTableCell className="header" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  Datos personales
                  <Tooltip title="Mostrar solo conversaciones con datos personales">
                    <Checkbox
                      size="small"
                      checked={showOnlyWithPersonalData}
                      onChange={(e) => setShowOnlyWithPersonalData(e.target.checked)}
                      sx={{
                        color: '#4aa6da',
                        '&.Mui-checked': {
                          color: '#4aa6da',
                        },
                        padding: '0'
                      }}
                    />
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell className="header">
                  <TableSortLabel
                    active={orderBy === 'fecha'}
                    direction={order === 'asc' ? 'asc' : 'desc'}
                    onClick={() => handleRequestSort('fecha')}
                    sx={{
                      '& .MuiTableSortLabel-icon': {
                        color: '#4aa6da !important',
                      },
                    }}
                  >
                    Fecha
                  </TableSortLabel>
                </StyledTableCell>
                <StyledTableCell className="header numeric">
                  <Tooltip 
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Relevancia: Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es relevante para la empresa. Por ejemplo, si es de tipo test o solo del tipo saludos sin más tendría una relevancia de 0. Además, si se han rellenado campos como email o teléfono tendrá una relevancia mayor.
                      </Typography>
                    }
                    placement="top"
                    enterDelay={1000}
                  >
                    <TableSortLabel
                      active={orderBy === 'relevancia'}
                      direction={order === 'asc' ? 'asc' : 'desc'}
                      onClick={() => handleRequestSort('relevancia')}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& .MuiTableSortLabel-icon': {
                          marginLeft: '4px',
                          position: 'static',
                          opacity: 0,
                          transition: 'opacity 0.2s'
                        },
                        '&:hover .MuiTableSortLabel-icon': {
                          opacity: 1,
                        },
                        '&.Mui-active': {
                          color: '#ecf2f6',
                          '& .MuiTableSortLabel-icon': {
                            opacity: 1,
                            color: '#4aa6da'
                          }
                        }
                      }}
                    >
                      Rel
                    </TableSortLabel>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell className="header numeric">
                  <Tooltip 
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Urgencia: Es un valor numérico subjetivo del 0 al 10 indicando la urgencia de la conversación o solicitud. Si es muy urgente se dará el máximo valor de 10 o si no un valor más bajo, hasta 0, que es valor mínimo. Por ejemplo, si el usuario necesita urgentemente ayuda o tiene un problema grave se podría asignar un valor de urgencia muy alto.
                      </Typography>
                    }
                    placement="top"
                    enterDelay={1000}
                  >
                    <TableSortLabel
                      active={orderBy === 'urgencia'}
                      direction={order === 'asc' ? 'asc' : 'desc'}
                      onClick={() => handleRequestSort('urgencia')}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& .MuiTableSortLabel-icon': {
                          marginLeft: '4px',
                          position: 'static',
                          opacity: 0,
                          transition: 'opacity 0.2s'
                        },
                        '&:hover .MuiTableSortLabel-icon': {
                          opacity: 1,
                        },
                        '&.Mui-active': {
                          color: '#ecf2f6',
                          '& .MuiTableSortLabel-icon': {
                            opacity: 1,
                            color: '#4aa6da'
                          }
                        }
                      }}
                    >
                      Urg
                    </TableSortLabel>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell className="header numeric">
                  <Tooltip 
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Valoración: Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es valiosa comercialmente (máximo valor de 10) o no (valor mínimo de 0). Por ejemplo, si el usuario ha solicitado mucha información o se le ve muy interesado en el producto o interactúa mucho con el asistente se podría considerar una valoración muy alta.
                      </Typography>
                    }
                    placement="top"
                    enterDelay={1000}
                  >
                    <TableSortLabel
                      active={orderBy === 'valoracion'}
                      direction={order === 'asc' ? 'asc' : 'desc'}
                      onClick={() => handleRequestSort('valoracion')}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& .MuiTableSortLabel-icon': {
                          marginLeft: '4px',
                          position: 'static',
                          opacity: 0,
                          transition: 'opacity 0.2s'
                        },
                        '&:hover .MuiTableSortLabel-icon': {
                          opacity: 1,
                        },
                        '&.Mui-active': {
                          color: '#ecf2f6',
                          '& .MuiTableSortLabel-icon': {
                            opacity: 1,
                            color: '#4aa6da'
                          }
                        }
                      }}
                    >
                      Val
                    </TableSortLabel>
                  </Tooltip>
                </StyledTableCell>
                <StyledTableCell className="header numeric">
                  <Tooltip 
                    title={
                      <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                        Resolución: Es un valor numérico subjetivo del 0 al 10 indicando si se ha resuelto la consulta o incidencia o pregunta. El valor de 10 indica que se ha resuelto, por ejemplo, si el asistente ha contestado totalmente la pregunta del usuario.
                      </Typography>
                    }
                    placement="top"
                    enterDelay={1000}
                  >
                    <TableSortLabel
                      active={orderBy === 'resuelto'}
                      direction={order === 'asc' ? 'asc' : 'desc'}
                      onClick={() => handleRequestSort('resuelto')}
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& .MuiTableSortLabel-icon': {
                          marginLeft: '4px',
                          position: 'static',
                          opacity: 0,
                          transition: 'opacity 0.2s'
                        },
                        '&:hover .MuiTableSortLabel-icon': {
                          opacity: 1,
                        },
                        '&.Mui-active': {
                          color: '#ecf2f6',
                          '& .MuiTableSortLabel-icon': {
                            opacity: 1,
                            color: '#4aa6da'
                          }
                        }
                      }}
                    >
                      Res
                    </TableSortLabel>
                  </Tooltip>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(filteredAndSortedChats || []).map((chat) => (
                <StyledTableRow 
                  key={chat.chatid}
                  onClick={() => handleChatClick(chat)}
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledTableCell className="consulta">
                    <span className="consulta-text">{chat.consulta}</span>
                    <span className="respuesta-text">{chat.respuesta}</span>
                  </StyledTableCell>
                  <StyledTableCell>
                    {[
                      chat.nombre,
                      chat.telefono,
                      chat.email,
                      chat.direccion,
                      chat.referencias
                    ].filter(Boolean).join(', ') || ''}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDateTime(chat.fecha, chat.hora)}
                  </StyledTableCell>
                  <StyledTableCell className="numeric" sx={{ 
                    color: getColorForValue(chat.relevancia),
                    fontWeight: 600,
                    fontSize: '0.9rem',
                  }}>
                    {chat.relevancia}
                  </StyledTableCell>
                  <StyledTableCell className="numeric" sx={{ 
                    color: getColorForValueUrg(chat.urgencia),
                    fontWeight: 600,
                    fontSize: '0.9rem',
                  }}>
                    {chat.urgencia}
                  </StyledTableCell>
                  <StyledTableCell className="numeric" sx={{ 
                    color: getColorForValue(chat.valoracion),
                    fontWeight: 600,
                    fontSize: '0.9rem',
                  }}>
                    {chat.valoracion}
                  </StyledTableCell>
                  <StyledTableCell className="numeric" sx={{ 
                    color: getColorForValue(chat.resuelto),
                    fontWeight: 600,
                    fontSize: '0.9rem',
                  }}>
                    {chat.resuelto}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <StyledDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2, borderBottom: '1px solid #205a7b' }}>
          <Typography variant="h6" component="div" sx={{ color: '#ecf2f6' }}>
            Detalle de la Conversación
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#96c3dd',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 3, backgroundColor: '#0b151b' }}>
          {chatDetail && (
            <>
              <CategoryLabel>Información</CategoryLabel>
              <Grid container spacing={2} sx={{ mb: 3 }}>
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledMetricBox sx={{ minHeight: '90px' }}>
                    <Typography className="label">Fecha</Typography>
                    <Typography className="date">
                      {formatDateTimeForBox(chatDetail.fecha, chatDetail.hora).date}
                    </Typography>
                    <Typography className="time">
                      {formatDateTimeForBox(chatDetail.fecha, chatDetail.hora).time}
                    </Typography>
                  </StyledMetricBox>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledMetricBox sx={{ minHeight: '90px' }}>
                    <Typography className="label">Relevancia</Typography>
                    <Typography 
                      className="value"
                      sx={{ color: getColorForValue(chatDetail.relevancia) }}
                    >
                      {chatDetail.relevancia}
                    </Typography>
                  </StyledMetricBox>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledMetricBox sx={{ minHeight: '90px' }}>
                    <Typography className="label">Urgencia</Typography>
                    <Typography 
                      className="value"
                      sx={{ color: getColorForValueUrg(chatDetail.urgencia) }}
                    >
                      {chatDetail.urgencia}
                    </Typography>
                  </StyledMetricBox>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledMetricBox sx={{ minHeight: '90px' }}>
                    <Typography className="label">Valoración</Typography>
                    <Typography 
                      className="value"
                      sx={{ color: getColorForValue(chatDetail.valoracion) }}
                    >
                      {chatDetail.valoracion}
                    </Typography>
                  </StyledMetricBox>
                </Grid>
                <Grid item xs={12} sm={6} md={2.4}>
                  <StyledMetricBox sx={{ minHeight: '90px' }}>
                    <Typography className="label">Resolución</Typography>
                    <Typography 
                      className="value"
                      sx={{ color: getColorForValue(chatDetail.resuelto) }}
                    >
                      {chatDetail.resuelto}
                    </Typography>
                  </StyledMetricBox>
                </Grid>
              </Grid>

              {hasPersonalData(chatDetail) && (
                <>
                  <CategoryLabel>Datos Personales</CategoryLabel>
                  <Grid container spacing={2} sx={{ mb: 3 }}>
                    {getPersonalDataBoxes(chatDetail).map((box, index) => (
                      <Grid 
                        item 
                        key={box.label}
                        xs={12} 
                        sm={6} 
                        md={2.4}
                      >
                        <StyledMetricBox sx={{ minHeight: '90px' }}>
                          <Typography className="label">{box.label}</Typography>
                          <Typography className="personalDataValue">
                            {box.value}
                          </Typography>
                        </StyledMetricBox>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
              <ChatContainer>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column',
                  minHeight: '50px',
                }}>
                  {formatChatText(chatDetail.texto).map((msg, index) => (
                    <MessageBubble key={index} isUser={msg.isUser}>
                      <Typography variant="caption" sx={{ 
                        color: msg.isUser ? '#96c3dd' : '#4aa6da',
                        display: 'block',
                        marginBottom: '4px',
                        fontWeight: 'bold'
                      }}>
                        {msg.speaker}
                      </Typography>
                      <Typography>
                        {msg.message}
                      </Typography>
                    </MessageBubble>
                  ))}
                </Box>
              </ChatContainer>
            </>
          )}
        </DialogContent>
      </StyledDialog>
    </Container>
  );
};

export default Conversations;
