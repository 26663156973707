import React, { useState, useEffect } from 'react';
import { fetchData } from '../services/api';
import {
  Box,
  Typography,
  Paper,
  Container,
  Grid,
  CircularProgress,
  useTheme,
  useMediaQuery,
  IconButton,
  Tooltip as MuiTooltip,
} from '@mui/material';
import DateRangePicker from '../components/DateRangePicker';
import dayjs from 'dayjs';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Cell,
  LineChart,
  Line,
} from 'recharts';
import { useDateRange } from '../context/DateContext';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const getColorByValoration = (valoration) => {
  // Escala de colores de rojo a verde con mejor progresión
  const colors = {
    0: '#ef4444', // Rojo
    1: '#f87171',
    2: '#fb923c',
    3: '#fbbf24',
    4: '#facc15',
    5: '#fde047',
    6: '#bef264',
    7: '#86efac',
    8: '#4ade80',
    9: '#22c55e',
    10: '#16a34a', // Verde
  };
  return colors[valoration] || '#6c757d';
};

const getActiveColorByValoration = (valoration) => {
  // Versión más clara del mismo color para el hover
  const colors = {
    0: '#dc3545', // Rojo oscuro más claro
    1: '#dc4555',
    2: '#dc5565',
    3: '#dc6575',
    4: '#dc7585',
    5: '#dc8595',
    6: '#34c759',
    7: '#2ecc71',
    8: '#29b35e',
    9: '#248a53',
    10: '#21793b', // Verde oscuro más claro
  };
  return colors[valoration] || '#868e96';
};

const getColorByUrgency = (urgency) => {
  // Escala de colores de verde a rojo (invertida respecto a valoración)
  const colors = {
    0: '#16a34a', // Verde
    1: '#22c55e',
    2: '#4ade80',
    3: '#86efac',
    4: '#bef264',
    5: '#fde047',
    6: '#fbbf24',
    7: '#fb923c',
    8: '#f87171',
    9: '#ef4444',
    10: '#dc2626', // Rojo
  };
  return colors[urgency] || '#6c757d';
};

const getActiveColorByUrgency = (urgency) => {
  // Versión más clara del mismo color para el hover
  const colors = {
    0: '#21793b', // Verde oscuro más claro
    1: '#248a53',
    2: '#29b35e',
    3: '#2ecc71',
    4: '#34c759',
    5: '#dc8595',
    6: '#dc6575',
    7: '#dc5565',
    8: '#dc4555',
    9: '#dc3545',
    10: '#b91c1c', // Rojo oscuro más claro
  };
  return colors[urgency] || '#868e96';
};

const cardStyles = {
  elevation: 3,
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: '#0b151b',
  border: '1px solid #205a7b',
  borderRadius: 2,
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 4px 20px rgba(150, 195, 221, 0.15)',
    borderColor: '#4aa6da',
  },
};

const getComparisonText = (startDate, endDate) => {
  const diffDays = endDate.diff(startDate, 'day') + 1;
  const isStartOfMonth = startDate.date() === 1;
  const isEndOfMonth = endDate.date() === endDate.daysInMonth();
  const isFullMonth = isStartOfMonth && isEndOfMonth && diffDays >= 28;

  // Caso especial para periodos comunes
  if (diffDays === 1) {
    return 'desde ayer';
  } else if (diffDays === 7) {
    return 'desde la semana pasada';
  } else if (diffDays === 14) {
    return 'desde hace dos semanas';
  } else if (isFullMonth) {
    return 'desde el mes pasado';
  } else if (diffDays <= 7) {
    return 'desde la semana pasada';
  } else if (diffDays <= 14) {
    return 'desde hace dos semanas';
  } else if (diffDays <= 31) {
    return 'desde el mes pasado';
  }
  
  // Para periodos más largos
  const months = Math.floor(diffDays / 30);
  if (months > 1) {
    return `desde hace ${months} meses`;
  }
  
  return 'desde el periodo anterior';
};

const StatCard = ({ title, value, description, growth, startDate, endDate }) => (
  <Paper sx={cardStyles}>
    <Typography variant="h6" sx={{ color: '#96c3dd' }}>
      {title}
    </Typography>
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mb: 1 }}>
      <Typography variant="h4" sx={{ color: '#ecf2f6' }}>
        {value}
      </Typography>
      {growth !== undefined && (
        <Typography 
          variant="body2" 
          sx={{ 
            color: growth >= 0 ? '#4ade80' : '#ef4444',
            fontSize: '0.875rem',
            lineHeight: 1.2
          }}
        >
          {growth >= 0 ? '+' : ''}{growth}% {getComparisonText(startDate, endDate)}
        </Typography>
      )}
    </Box>
    <Typography variant="body2" sx={{ color: '#96c3dd' }}>
      {description}
    </Typography>
  </Paper>
);

const ChartCard = ({ title, children }) => (
  <Paper sx={cardStyles}>
    {title}
    {children}
  </Paper>
);

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [data, setData] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { startDate, setStartDate, endDate, setEndDate } = useDateRange();

  useEffect(() => {
    fetchDataForDateRange();
  }, [startDate, endDate]);

  // Escuchar cambios en el chatbot seleccionado
  useEffect(() => {
    const handleChatbotChange = () => {
      fetchDataForDateRange();
    };

    window.addEventListener('chatbotChanged', handleChatbotChange);
    return () => {
      window.removeEventListener('chatbotChanged', handleChatbotChange);
    };
  }, []);

  const fetchDataForDateRange = async () => {
    try {
      setLoading(true);
      const user = JSON.parse(sessionStorage.getItem('user'));
      const selectedChatbot = user.chatbot || user.selectedChatbot;
      
      if (!selectedChatbot) {
        setError('No hay chatbot seleccionado');
        return;
      }

      // Calcular el periodo anterior
      const currentStartDate = dayjs(startDate);
      const currentEndDate = dayjs(endDate);
      const diffDays = currentEndDate.diff(currentStartDate, 'day') + 1;
      
      const previousEndDate = currentStartDate.subtract(1, 'day');
      const previousStartDate = previousEndDate.subtract(diffDays - 1, 'day');

      // Obtener datos del periodo actual
      const currentResponse = await fetchData(
        1,
        selectedChatbot,
        currentStartDate.format('YYYYMMDD'),
        currentEndDate.format('YYYYMMDD')
      );

      // Obtener datos del periodo anterior
      const previousResponse = await fetchData(
        1,
        selectedChatbot,
        previousStartDate.format('YYYYMMDD'),
        previousEndDate.format('YYYYMMDD')
      );

      setData(currentResponse || []);
      setPreviousData(previousResponse || []);
      setError(null);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Error al obtener los datos. Por favor, inténtelo de nuevo.');
      setData([]);
      setPreviousData([]);
    } finally {
      setLoading(false);
    }
  };

  // Calcular estadísticas
  const safeData = data || []; // Aseguramos que siempre sea un array
  const safePreviousData = previousData || []; // Aseguramos que siempre sea un array

  const calculateStats = (chats) => {
    if (!chats || chats.length === 0) return { total: 0, avgMessages: 0, avgDuration: 0, avgUrgency: 0 };
    
    const total = chats.length;
    const avgMessages = chats.reduce((acc, chat) => acc + (chat.nummsj || 0), 0) / total;
    const avgDuration = chats.reduce((acc, chat) => acc + (chat.duracion || 0), 0) / total;
    const avgUrgency = chats.reduce((acc, chat) => acc + (chat.urgencia || 0), 0) / total;
    
    return {
      total,
      avgMessages: Math.round(avgMessages * 10) / 10,
      avgDuration: Math.round(avgDuration * 10) / 10,
      avgUrgency: Math.round(avgUrgency * 10) / 10
    };
  };

  const calculateGrowth = (current, previous) => {
    if (previous === 0) return current > 0 ? 100 : 0;
    return Math.round(((current - previous) / previous) * 100);
  };

  const stats = calculateStats(safeData);
  const previousStats = calculateStats(safePreviousData);

  const growthStats = {
    total: calculateGrowth(stats.total, previousStats.total),
    avgMessages: calculateGrowth(stats.avgMessages, previousStats.avgMessages),
    avgDuration: calculateGrowth(stats.avgDuration, previousStats.avgDuration)
  };

  // Datos para gráficos
  const hoursData = safeData.reduce((acc, curr) => {
    const startTime = curr.fecha_inicio;
    if (startTime) {
      // Extraer la hora del timestamp (está en formato HHmm)
      const hour = parseInt(startTime.substring(0, 2));
      acc[hour] = (acc[hour] || 0) + 1;
    }
    return acc;
  }, {});

  // Crear array con todas las horas (0-23)
  const chartDataHours = Array.from({ length: 24 }, (_, i) => ({
    hour: i,
    count: hoursData[i] || 0
  }));

  const resolutionData = safeData.reduce((acc, curr) => {
    const resolution = curr.resuelto;
    acc[resolution] = (acc[resolution] || 0) + 1;
    return acc;
  }, {});

  const valorationData = safeData.reduce((acc, curr) => {
    const valoration = curr.valoracion;
    acc[valoration] = (acc[valoration] || 0) + 1;
    return acc;
  }, {});

  const urgencyData = safeData.reduce((acc, curr) => {
    const urgency = curr.urgencia;
    acc[urgency] = (acc[urgency] || 0) + 1;
    return acc;
  }, {});

  const relevanceData = safeData.reduce((acc, curr) => {
    const relevance = curr.relevancia;
    acc[relevance] = (acc[relevance] || 0) + 1;
    return acc;
  }, {});

  // Función auxiliar para generar array con todos los niveles
  const generateFullRange = () => {
    return Array.from({ length: 11 }, (_, i) => ({
      nivel: `${i}`,
      nivel_num: i
    }));
  };

  const chartDataResolution = generateFullRange().map(item => ({
    ...item,
    cantidad: resolutionData[item.nivel_num] || 0
  }));

  const chartDataValoration = generateFullRange().map(item => ({
    ...item,
    cantidad: valorationData[item.nivel_num] || 0
  }));

  const chartDataUrgency = generateFullRange().map(item => ({
    ...item,
    cantidad: urgencyData[item.nivel_num] || 0
  }));

  const chartDataRelevance = generateFullRange().map(item => ({
    ...item,
    cantidad: relevanceData[item.nivel_num] || 0
  }));

  // Calcular medias
  const calculateAverage = (data) => {
    const total = data.reduce((acc, curr) => {
      const nivel = parseInt(curr.nivel);
      return acc + (nivel * curr.cantidad);
    }, 0);
    const totalCount = data.reduce((acc, curr) => acc + curr.cantidad, 0);
    return totalCount > 0 ? (total / totalCount).toFixed(1) : "0.0";
  };

  const resolutionAvg = calculateAverage(chartDataResolution);
  const urgencyAvg = calculateAverage(chartDataUrgency);
  const relevanceAvg = calculateAverage(chartDataRelevance);
  const valorationAvg = calculateAverage(chartDataValoration);

  return (
    <Container maxWidth="xl" sx={{ py: 3, backgroundColor: '#0b151b', minHeight: '100vh' }}>
      <Paper 
        elevation={3}
        sx={{ 
          p: { xs: 2, sm: 3 },
          mb: { xs: 2, sm: 3 },
          borderRadius: 2,
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'stretch' : 'center',
          gap: 2,
          backgroundColor: '#0b151b',
          border: '1px solid #205a7b',
        }}
      >
        <Box>
          <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#ecf2f6' }}>
            Estadísticas
          </Typography>
          <Typography variant="body1" sx={{ color: '#96c3dd' }}>
            Panel de control con métricas y análisis de las conversaciones del 4LogicOne
          </Typography>
        </Box>
        <Box>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
          />
        </Box>
      </Paper>

      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Paper 
          sx={{ 
            p: 3, 
            textAlign: 'center',
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
          }}
        >
          <Typography color="error">{error}</Typography>
        </Paper>
      ) : safeData.length === 0 ? (
        <Paper 
          sx={{ 
            p: 3, 
            textAlign: 'center',
            backgroundColor: '#0b151b',
            border: '1px solid #205a7b',
          }}
        >
          <Typography sx={{ color: '#96c3dd' }}>
            No hay registros de conversaciones para el período seleccionado.
          </Typography>
        </Paper>
      ) : (
        <>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Total Conversaciones"
                value={stats.total}
                growth={growthStats.total}
                description="Número total de interacciones"
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Media de Mensajes"
                value={stats.avgMessages}
                growth={growthStats.avgMessages}
                description="Promedio de mensajes por conversación"
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StatCard
                title="Duración Media (seg)"
                value={stats.avgDuration}
                growth={growthStats.avgDuration}
                description="Duración promedio de las conversaciones"
                startDate={startDate}
                endDate={endDate}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Paper sx={cardStyles}>
                <Typography variant="h6" sx={{ color: '#96c3dd', mb: 1 }}>
                  Distribución por Hora
                </Typography>
                <Box sx={{ flexGrow: 1, height: 90, mb: 0 }}>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      data={Array.from({ length: 24 }, (_, i) => ({
                        hour: i,
                        count: safeData.filter(chat => {
                          const hora = chat.hora || '';
                          return parseInt(hora.substring(0, 2)) === i;
                        }).length
                      }))}
                      margin={{ top: 5, right: 5, left: 5, bottom: 5 }}
                    >
                      <XAxis 
                        dataKey="hour"
                        stroke="#96c3dd"
                        fontSize={10}
                        tickFormatter={(hour) => `${hour}h`}
                        interval={3}
                      />
                      <YAxis 
                        stroke="#96c3dd"
                        fontSize={10}
                        width={20}
                      />
                      <Tooltip
                        cursor={{ stroke: '#205a7b', strokeWidth: 1 }}
                        contentStyle={{
                          backgroundColor: '#0b151b',
                          border: '1px solid #205a7b',
                          borderRadius: '4px',
                          padding: '8px'
                        }}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <div style={{
                                backgroundColor: '#0b151b',
                                border: '1px solid #205a7b',
                                borderRadius: '4px',
                                padding: '10px'
                              }}>
                                <p style={{ color: '#96c3dd', margin: '0', fontSize: '12px' }}>
                                  Hora: {payload[0].payload.hour}:00
                                </p>
                                <p style={{ color: '#4aa6da', margin: '0', fontSize: '12px' }}>
                                  Conversaciones: {payload[0].value}
                                </p>
                              </div>
                            );
                          }
                          return null;
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="count"
                        stroke="#4aa6da"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            {/* Primera fila: Resolución y Urgencia */}
            <Grid item xs={12} md={6}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd' }}>Resolución</Typography>
                    <MuiTooltip 
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando si se ha resuelto la consulta o incidencia o pregunta. El valor de 10 indica que se ha resuelto, por ejemplo, si el asistente ha contestado totalmente la pregunta del usuario.
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="h6" sx={{ color: '#96c3dd' }}>Media: {resolutionAvg}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart 
                    data={chartDataResolution}
                    margin={{ top: 5, right: 20, left: -20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#205a7b" />
                    <XAxis dataKey="nivel" stroke="#96c3dd" />
                    <YAxis stroke="#96c3dd" />
                    <Tooltip 
                      cursor={{fill: '#205a7b', opacity: 0.3}}
                      contentStyle={{ backgroundColor: '#0b151b', border: '1px solid #205a7b' }}
                      labelStyle={{ color: '#96c3dd', fontWeight: 'bold' }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          return (
                            <div style={{
                              backgroundColor: '#0b151b',
                              border: '1px solid #205a7b',
                              borderRadius: '4px',
                              padding: '10px'
                            }}>
                              <p style={{ color: '#96c3dd', margin: '0', fontWeight: 'bold' }}>
                                Resolución {payload[0].payload.nivel}
                              </p>
                              <p style={{ color: '#4aa6da', margin: '0' }}>
                                Cantidad: {payload[0].value}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar dataKey="cantidad" name="Nivel" fill="#4aa6da">
                      {chartDataResolution.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`}
                          fill="#4aa6da"
                          fillOpacity={0.9}
                          stroke="#4aa6da"
                          strokeWidth={1}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd' }}>Urgencia</Typography>
                    <MuiTooltip 
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando la urgencia de la conversación o solicitud. Si es muy urgente se dará el máximo valor de 10 o si no un valor más bajo, hasta 0, que es valor mínimo. Por ejemplo, si el usuario necesita urgentemente ayuda o tiene un problema grave se podría asignar un valor de urgencia muy alto.
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="h6" sx={{ color: '#96c3dd' }}>Media: {urgencyAvg}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart 
                    data={chartDataUrgency}
                    margin={{ top: 5, right: 20, left: -20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#205a7b" />
                    <XAxis dataKey="nivel" stroke="#96c3dd" />
                    <YAxis stroke="#96c3dd" />
                    <Tooltip 
                      cursor={{fill: '#205a7b', opacity: 0.3}}
                      contentStyle={{ backgroundColor: '#0b151b', border: '1px solid #205a7b' }}
                      labelStyle={{ color: '#96c3dd', fontWeight: 'bold' }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const nivel = parseInt(payload[0].payload.nivel);
                          const color = getColorByUrgency(nivel);
                          return (
                            <div style={{
                              backgroundColor: '#0b151b',
                              border: '1px solid #205a7b',
                              borderRadius: '4px',
                              padding: '10px'
                            }}>
                              <p style={{ color: '#96c3dd', margin: '0', fontWeight: 'bold' }}>
                                Urgencia {nivel}
                              </p>
                              <p style={{ color: color, margin: '0' }}>
                                Cantidad: {payload[0].value}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar dataKey="cantidad" name="Nivel" fill="#16a34a">
                      {chartDataUrgency.map((entry, index) => {
                        const nivel = parseInt(entry.nivel);
                        return (
                          <Cell 
                            key={`cell-${index}`}
                            fill={getColorByUrgency(nivel)}
                            fillOpacity={0.9}
                            stroke={getActiveColorByUrgency(nivel)}
                            strokeWidth={1}
                          />
                        );
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>

            {/* Segunda fila: Relevancia y Valoración */}
            <Grid item xs={12} md={6}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd' }}>Relevancia</Typography>
                    <MuiTooltip 
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es relevante para la empresa. Por ejemplo, si es de tipo test o solo del tipo saludos sin más tendría una relevancia de 0. Además, si se han rellenado campos como email o teléfono tendrá una relevancia mayor.
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="h6" sx={{ color: '#96c3dd' }}>Media: {relevanceAvg}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart 
                    data={chartDataRelevance}
                    margin={{ top: 5, right: 20, left: -20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#205a7b" />
                    <XAxis dataKey="nivel" stroke="#96c3dd" />
                    <YAxis stroke="#96c3dd" />
                    <Tooltip 
                      cursor={{fill: '#205a7b', opacity: 0.3}}
                      contentStyle={{ backgroundColor: '#0b151b', border: '1px solid #205a7b' }}
                      labelStyle={{ color: '#96c3dd', fontWeight: 'bold' }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          return (
                            <div style={{
                              backgroundColor: '#0b151b',
                              border: '1px solid #205a7b',
                              borderRadius: '4px',
                              padding: '10px'
                            }}>
                              <p style={{ color: '#96c3dd', margin: '0', fontWeight: 'bold' }}>
                                Relevancia {payload[0].payload.nivel}
                              </p>
                              <p style={{ color: '#f59e0b', margin: '0' }}>
                                Cantidad: {payload[0].value}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar dataKey="cantidad" name="Nivel" fill="#f59e0b">
                      {chartDataRelevance.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`}
                          fill="#f59e0b"
                          fillOpacity={0.9}
                          stroke="#f59e0b"
                          strokeWidth={1}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <ChartCard title={
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography variant="h6" sx={{ color: '#96c3dd' }}>Valoración</Typography>
                    <MuiTooltip 
                      title={
                        <Typography sx={{ fontSize: '0.9rem', p: 1 }}>
                          Es un valor numérico subjetivo del 0 al 10 indicando si la conversación es valiosa comercialmente (máximo valor de 10) o no (valor mínimo de 0). Por ejemplo, si el usuario ha solicitado mucha información o se le ve muy interesado en el producto o interactúa mucho con el asistente se podría considerar una valoración muy alta.
                        </Typography>
                      }
                      placement="right"
                    >
                      <IconButton size="small" sx={{ color: '#96c3dd', p: 0 }}>
                        <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                      </IconButton>
                    </MuiTooltip>
                  </Box>
                  <Typography variant="h6" sx={{ color: '#96c3dd' }}>Media: {valorationAvg}</Typography>
                </Box>
              }>
                <ResponsiveContainer width="100%" height={300}>
                  <BarChart 
                    data={chartDataValoration}
                    margin={{ top: 5, right: 20, left: -20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" stroke="#205a7b" />
                    <XAxis dataKey="nivel" stroke="#96c3dd" />
                    <YAxis stroke="#96c3dd" />
                    <Tooltip 
                      cursor={{fill: '#205a7b', opacity: 0.3}}
                      contentStyle={{ backgroundColor: '#0b151b', border: '1px solid #205a7b' }}
                      labelStyle={{ color: '#96c3dd', fontWeight: 'bold' }}
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const nivel = parseInt(payload[0].payload.nivel);
                          const color = getColorByValoration(nivel);
                          return (
                            <div style={{
                              backgroundColor: '#0b151b',
                              border: '1px solid #205a7b',
                              borderRadius: '4px',
                              padding: '10px'
                            }}>
                              <p style={{ color: '#96c3dd', margin: '0', fontWeight: 'bold' }}>
                                Valoración {nivel}
                              </p>
                              <p style={{ color: color, margin: '0' }}>
                                Cantidad: {payload[0].value}
                              </p>
                            </div>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar dataKey="cantidad" name="Nivel" fill="#16a34a">
                      {chartDataValoration.map((entry, index) => (
                        <Cell 
                          key={`cell-${index}`}
                          fill={getColorByValoration(parseInt(entry.nivel))}
                          fillOpacity={0.9}
                          stroke={getActiveColorByValoration(parseInt(entry.nivel))}
                          strokeWidth={1}
                        />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Home;
