import React, { createContext, useContext, useState, useEffect } from 'react';

const SelectedChatbotContext = createContext();

export const SelectedChatbotProvider = ({ children }) => {
  const [selectedChatbot, setSelectedChatbot] = useState(() => {
    // Intentar obtener el chatbot inicial del sessionStorage
    const userData = JSON.parse(sessionStorage.getItem('user'));
    return userData?.chatbot || null;
  });

  // Actualizar el chatbot cuando cambia el usuario en sessionStorage
  useEffect(() => {
    const handleStorageChange = () => {
      const userData = JSON.parse(sessionStorage.getItem('user'));
      if (userData?.chatbot) {
        setSelectedChatbot(userData.chatbot);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <SelectedChatbotContext.Provider value={{ selectedChatbot, setSelectedChatbot }}>
      {children}
    </SelectedChatbotContext.Provider>
  );
};

export const useSelectedChatbot = () => {
  const context = useContext(SelectedChatbotContext);
  if (!context) {
    throw new Error('useSelectedChatbot must be used within a SelectedChatbotProvider');
  }
  return context;
};
