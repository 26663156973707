import React, { useState, useEffect } from 'react';
import {
  Box,
  Paper,
  TextField,
  Button,
  Avatar,
  Typography,
  Container,
  IconButton,
} from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

const Profile = () => {
  const [user, setUser] = useState(null);
  const [avatar, setAvatar] = useState('');

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    setUser(userData);
    setAvatar(userData.avatar || ''); // Si no hay avatar, usa string vacío
  }, []);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        setAvatar(base64String);
        // Actualizar el avatar en sessionStorage
        const updatedUser = { ...user, avatar: base64String };
        sessionStorage.setItem('user', JSON.stringify(updatedUser));
        setUser(updatedUser);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Aquí irá la lógica para actualizar el perfil en el backend
    // Por ahora solo actualizamos el sessionStorage
    sessionStorage.setItem('user', JSON.stringify(user));
  };

  if (!user) return null;

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4 }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3
          }}
        >
          <Typography variant="h5" component="h1">
            Perfil de Usuario
          </Typography>

          <Box sx={{ position: 'relative' }}>
            <Avatar
              src={avatar}
              sx={{
                width: 100,
                height: 100,
                mb: 2
              }}
            />
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="avatar-upload"
              type="file"
              onChange={handleAvatarChange}
            />
            <label htmlFor="avatar-upload">
              <IconButton
                color="primary"
                component="span"
                sx={{
                  position: 'absolute',
                  bottom: 10,
                  right: -10,
                  backgroundColor: 'background.paper'
                }}
              >
                <PhotoCamera />
              </IconButton>
            </label>
          </Box>

          <TextField
            fullWidth
            label="Nombre de usuario"
            value={user.username}
            disabled
          />

          <TextField
            fullWidth
            label="Rol"
            value={user.role}
            disabled
          />

          <TextField
            fullWidth
            label="Chatbot asignado"
            value={user.chatbot}
            disabled
          />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
          >
            Guardar Cambios
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Profile;
