import React, { useState, useEffect } from 'react';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Box, 
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Avatar,
  Divider,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { Home, Chat, Analytics, AutoGraph, Menu as MenuIcon, Logout, Settings as SettingsIcon, School } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useDateRange } from '../../context/DateContext';
import { useSelectedChatbot } from '../../context/SelectedChatbotContext';

const drawerWidth = 240;

const menuItems = [
  {
    category: 'Seguimiento',
    items: [
      { text: 'Estadísticas', icon: <Analytics />, path: '/' },
      { text: 'Conversaciones', icon: <Chat />, path: '/conversations' },
      { text: 'Analíticas', icon: <AutoGraph />, path: '/analytics' }
    ]
  },
  {
    category: 'Configuración',
    items: [
      { text: 'Entrenamiento', icon: <School />, path: '/training' },
      { text: 'Ajustes', icon: <SettingsIcon />, path: '/settings' }
    ]
  }
];

const Sidebar = ({ mobileOpen, handleDrawerToggle }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [user, setUser] = useState(null);
  const { setStartDate, setEndDate } = useDateRange();
  const { selectedChatbot, setSelectedChatbot } = useSelectedChatbot();

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('user'));
    if (userData) {
      setUser(userData);
      const initialChatbot = userData.chatbot || userData.chatbots[0]?.id;
      setSelectedChatbot(initialChatbot);
    }
  }, [setSelectedChatbot]);

  const handleLogout = () => {
    sessionStorage.removeItem('user');
    navigate('/login');
  };

  const handleNavigation = (path) => {
    navigate(path);
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  const handleChatbotChange = (event) => {
    const chatbotId = event.target.value;
    setSelectedChatbot(chatbotId);
    
    const userData = JSON.parse(sessionStorage.getItem('user'));
    sessionStorage.setItem('user', JSON.stringify({
      ...userData,
      chatbot: chatbotId
    }));

    const end = dayjs();
    const start = end.subtract(30, 'day');
    setStartDate(start);
    setEndDate(end);

    window.dispatchEvent(new Event('chatbotChanged'));
  };

  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {/* User Profile Section */}
      <Box
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar
            src={user?.avatar}
            sx={{
              width: 40,
              height: 40,
            }}
          />
          <Box sx={{ flexGrow: 1, minWidth: 0 }}>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                color: '#ffffff',
                wordWrap: 'break-word',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                lineHeight: '1.2em',
                maxHeight: '2.4em'
              }}
            >
              {user?.name}
            </Typography>
          </Box>
          <Tooltip title="Cerrar sesión">
            <IconButton
              onClick={handleLogout}
              size="small"
              sx={{
                color: '#96c3dd',
                '&:hover': {
                  color: '#4aa6da'
                }
              }}
            >
              <Logout />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Solo mostrar el selector si hay más de un chatbot */}
        {user?.chatbots && user.chatbots.length > 1 && (
          <FormControl fullWidth sx={{ mt: 3 }}>
            <InputLabel id="chatbot-select-label" sx={{ color: '#96c3dd' }}>
              Cliente
            </InputLabel>
            <Select
              labelId="chatbot-select-label"
              id="chatbot-select"
              value={selectedChatbot || ''}
              onChange={handleChatbotChange}
              label="Chatbot"
              sx={{
                color: '#ecf2f6',
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#205a7b',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4aa6da',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#4aa6da',
                },
                '.MuiSvgIcon-root': {
                  color: '#96c3dd',
                }
              }}
            >
              {user.chatbots.map((chatbot) => (
                <MenuItem 
                  key={chatbot.id} 
                  value={chatbot.id}
                  sx={{
                    color: '#ecf2f6',
                    '&:hover': {
                      backgroundColor: 'rgba(74, 166, 218, 0.1)',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(74, 166, 218, 0.2)',
                      '&:hover': {
                        backgroundColor: 'rgba(74, 166, 218, 0.3)',
                      }
                    }
                  }}
                >
                  {chatbot.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Divider 
        sx={{ 
          borderColor: 'rgba(150, 195, 221, 0.2)',
          borderWidth: 2,
          my: 1
        }} 
      />

      {/* Menu Items */}
      <List sx={{ width: '100%', p: 1 }}>
        {menuItems.map((category) => (
          <Box key={category.category} sx={{ mb: 2 }}>
            <ListItem 
              sx={{ 
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: '4px',
                mb: 1
              }}
            >
              <ListItemText 
                primary={category.category} 
                primaryTypographyProps={{
                  fontWeight: 600,
                  fontSize: '0.9rem',
                  color: 'text.secondary'
                }}
              />
            </ListItem>
            <List component="div" disablePadding>
              {category.items.map((item) => (
                <ListItem
                  button
                  key={item.text}
                  onClick={() => handleNavigation(item.path)}
                  sx={{ 
                    pl: 3,
                    borderRadius: '4px',
                    mb: 0.5,
                    '&:hover': {
                      backgroundColor: 'rgba(25, 118, 210, 0.08)'
                    }
                  }}
                >
                  <ListItemIcon sx={{ 
                    minWidth: '40px',
                    color: 'text.secondary'
                  }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.text} 
                    primaryTypographyProps={{
                      fontSize: '0.875rem'
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{
              position: 'fixed',
              top: 16,
              left: 16,
              zIndex: 1200,
              color: '#ecf2f6',
              backgroundColor: '#1a2730',
              boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
              '&:hover': {
                backgroundColor: '#243441',
              }
            }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', 
                width: drawerWidth,
                backgroundColor: '#0b151b',
                color: '#ecf2f6',
                borderRight: '1px solid rgba(150, 195, 221, 0.12)'
              },
            }}
          >
            {drawer}
          </Drawer>
        </>
      ) : (
        <Drawer
          variant="permanent"
          open={true}
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              backgroundColor: '#0b151b',
              borderRight: '1px solid rgba(150, 195, 221, 0.12)'
            }
          }}
        >
          {drawer}
        </Drawer>
      )}
    </>
  );
};

export default Sidebar;
