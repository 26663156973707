import axios from 'axios';

// URL base para las peticiones a la API.
const BASE_URL = 'https://overlord.chat/api/v1/back/db';

const getToken = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));
  return user?.token;
};

const axiosInstance = axios.create({
  timeout: 30000, // 30 segundos
  headers: {
    'Content-Type': 'application/json',
  }
});

const retryRequest = async (fn, retries = 3, delay = 1000) => {
  try {
    return await fn();
  } catch (error) {
    if (retries === 0) throw error;
    await new Promise(resolve => setTimeout(resolve, delay));
    return retryRequest(fn, retries - 1, delay * 2);
  }
};

/**
 * Obtiene los datos generales para el dashboard.
 * @param {number} tipo - Tipo de consulta.
 * @param {string} chatbot - Nombre del chatbot.
 * @param {string} fecha1 - Fecha de inicio (formato YYYYMMDD).
 * @param {string} fecha2 - Fecha de fin (formato YYYYMMDD).
 * @returns {Promise<object>} - Respuesta del backend.
 */
export const fetchData = async (tipo, chatbot, fecha1, fecha2) => {
    try {
        const response = await axiosInstance.get(BASE_URL, {
            params: {
                tipo,
                chatbot,
                fecha1,
                fecha2,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener los datos:', error);
        throw error;
    }
};

/**
 * Obtiene los datos de una conversación específica.
 * @param {number} tipo - Tipo de consulta.
 * @param {string} chatbot - Nombre del chatbot.
 * @param {string} fecha1 - Fecha de inicio (formato YYYYMMDD).
 * @param {string} fecha2 - Fecha de fin (formato YYYYMMDD).
 * @param {string} chatid - ID del chat específico.
 * @returns {Promise<object>} - Respuesta del backend con la conversación.
 */
export const fetchChatConversation = async (tipo, chatbot, fecha1, fecha2, chatid) => {
    try {
        const response = await axiosInstance.get(BASE_URL, {
            params: {
                tipo,
                chatbot,
                fecha1,
                fecha2,
                vertexto: 1,
                chatid,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener la conversación:', error);
        throw error;
    }
};

/**
 * Obtiene los datos de texto del chatbot.
 * @param {string} chatbotId - ID del chatbot.
 * @returns {Promise<object>} - Respuesta del backend con los datos de texto.
 */
export const fetchTextData = async (chatbotId) => {
  try {
    const response = await axiosInstance.get(`https://overlord.chat/api/v1/back/text-data`, {
      params: { 
        id: chatbotId 
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error en fetchTextData:', error);
    throw error;
  }
};

/**
 * Envía un mensaje al chatbot y obtiene su respuesta
 * @param {string} chatbotId - ID del chatbot
 * @param {string} message - Mensaje del usuario
 * @param {string} chatId - ID de la conversación (opcional)
 * @returns {Promise<object>} - Respuesta del chatbot
 */
export const sendChatMessage = async (chatbotId, message, chatId = '') => {
  try {
    const API_URL = "https://overlord.chat/api/v1/chat";
    console.log('Enviando datos:', { clientCode: chatbotId, chatId, message });
    
    const response = await axios.post(API_URL, {
      clientCode: chatbotId,
      chatId: chatId || '', // Asegurarse de enviar vacío en lugar de null
      message: message,
    }, {
      timeout: 60000,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      validateStatus: function (status) {
        return status >= 200 && status < 500; 
      }
    });

    console.log('Respuesta del servidor:', response.data);
    if (!response.data) {
      throw new Error('Respuesta vacía del servidor');
    }

    return response.data;
  } catch (error) {
    console.error('Error al enviar el mensaje:', error);
    throw error;
  }
};

/**
 * Guarda las instrucciones del chatbot
 * @param {string} clientCode - Código del cliente
 * @param {string} instructions - Texto de las instrucciones
 * @returns {Promise<void>} - Promesa vacía que se resuelve cuando se guarda correctamente
 */
export const saveInstructions = async (clientCode, instructions) => {
  try {
    const API_URL = "https://overlord.chat/api/v1/back/save-data";
    
    const response = await fetch(`${API_URL}?id=${clientCode}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/plain'
      },
      body: instructions
    });

    if (!response.ok) {
      throw new Error(`Error al guardar: ${response.status}`);
    }

    // No intentamos parsear la respuesta como JSON
    return;
  } catch (error) {
    console.error('Error al guardar las instrucciones:', error);
    throw error;
  }
};